import * as Yup from "yup";

export const signImSchema = Yup.object({
  username: Yup.string().required("Enter UserName"),
  password: Yup.string().required("Enter Password"),
});
export const forgot = Yup.object({
  email: Yup.string().email("Invalid Email").required("Enter Email"),
});

export const updateProfile = Yup.object({
  email: Yup.string()
    .email("Invalid E-mail")
    .required("Please enter the e-mail"),
  old_password: Yup.string().when({
    is: (old_password) => old_password,
    then: Yup.string().required("Please enter th current password"),
    otherwise: Yup.string().notRequired(),
  }),
  password: Yup.string().when("old_password", {
    is: (old_password) => old_password,
    then: Yup.string().required("Please enter the new password"),
    otherwise: Yup.string().notRequired(),
  }),

  confirm_password: Yup.string()
    .when("password", {
      is: (password) => password,
      then: Yup.string().required("Please verify the new password"),
      otherwise: Yup.string().notRequired(),
    })
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

export const websiteChange = Yup.object({
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
  phone: Yup.string()
    .matches(/^[0-9- ]+$/, "Invalid phone number")
    .min(10, "The phone must be at least 10 characters.")
    .max(12, "The phone may not be greater than 12 characters.")
    .required("Please enter the phone"),
});
export const updateNewsleter = Yup.object({
  subject: Yup.string().required("Please enter the subject"),
  body: Yup.string().required("Please enter in the body"),
});

export const updateGift = Yup.object({
  title: Yup.string().required("Please enter the product name"),
  description: Yup.string().required("Please enter the description "),
  points: Yup.number()
    .min(0, "Should be entered as a positive value")
    .required("Please enter the points "),
});

export const newGiftAdd = Yup.object({
  title: Yup.string().required("Please enter the product name"),
  description: Yup.string().required("Please enter the description "),

  points: Yup.number()
    .min(0, "Should be entered as a positive value")
    .required("Please enter the points "),
  image: Yup.string().required("Please select the image "),
  bb_price: Yup.string().when("type", {
    is: "bestbuy",
    then: Yup.string().required(
      "The bestbuy price field is required when product type is selected to BestBuy"
    ),
    otherwise: Yup.string(),
  }),
  sku: Yup.string().when("type", {
    is: "bestbuy",
    then: Yup.string().required(
      "The sku field is required when product type is selected to BestBuy"
    ),
    otherwise: Yup.string(),
  }),
});

export const quizSearch = Yup.object({
  fieldtype: Yup.string().required("Please Select a Column"),
  searchval: Yup.string().required("Please fill the search field"),
});

export const addEmailFun = Yup.object({
  email: Yup.string().email("Invalid Email").required("Please enter the email"),
});

export const addLogoFun = Yup.object({
  image: Yup.string().required("Please select the logo"),
});

export const updateVoucher = Yup.object({
  Company: Yup.string()
    .min(3, "The company must be at least 3 characters.")
    .max(50, "The company must not greater than 50 characters.")
    .required("The company field is required"),
  Certificate: Yup.string()
    .min(11, "The certificate must be at least 11 characters.")
    .required("The certificate field is required.	"),

  First: Yup.string()
    .min(3, "The first name field must be at least 3 characters.")
    .max(50, "The first name field must not greater than 50 characters.")
    .required("The first name field is required.	"),

  State: Yup.string()
    .min(2, "The state field must be at least 2 characters.")
    .max(20, "The state field must not greater than 20 characters.")
    .required("The state field is required."),
  Job: Yup.number()
    .min(0, "The job field is required.")
    .required("The job field is required."),
  Amount: Yup.number().notRequired(),
  Denomination: Yup.number().notRequired(),
  shipCost: Yup.number().notRequired(),

  Last: Yup.string()
    .min(3, "The last name field must be at least 3 characters.")
    .max(50, "The last name field must not greater than 50 characters.")
    .required("The last name field is required."),
  City: Yup.string()
    .min(3, "The city field must be at least 3 characters.")
    .max(30, "The city field must not greater than 30 characters.")
    .required("The city field is required."),
  Zip: Yup.string().required("The zip field is required."),
  Street: Yup.string().required("The address field is required."),
});

export const importDocument = Yup.object({
  file: Yup.string().required("Please select the file"),
});

export const pavlinaUpdateusedVoucher = Yup.object({
  Company: Yup.string()
    .min(3, "The company must be at least 3 characters.")
    .max(50, "The company must not greater than 50 characters.")
    .required("The company field is required"),
  Certificate: Yup.string()
    .min(11, "The certificate must be at least 11 characters.")
    .required("The certificate field is required.	"),

  First: Yup.string()
    .min(3, "The first name field must be at least 3 characters.")
    .max(50, "The first name field must not greater than 50 characters.")
    .matches(/^[A-Za-z]+$/, "The first name format is invalid")
    .required("The first name field is required."),
  address1: Yup.string().required("The address field is required."),
  State: Yup.string()
    .min(2, "The state field must be at least 2 characters.")
    .max(20, "The state field must not greater than 20 characters.")
    .required("The state field is required."),
  Job: Yup.string()
    .matches(/^[0-9]+$/, "Invalid job")
    .min(5, "The job must be at least 5 characters.")
    .max(6, "The job must not be greater than 6 characters.")
    .required("The job field is required."),

  // Denomination: Yup.number()
  //   .min(1, "The denomination field is required.")
  //   .required(),

  email: Yup.string()
    .email("Invalid Email")
    .required("The email field is required."),
  phone: Yup.string()
    .matches(/^[0-9- ]+$/, "Invalid phone number")
    .min(10, "The phone must be at least 10 characters.")
    .max(12, "The phone may not be greater than 12 characters.")
    .required("The phone field is required."),

  Last: Yup.string()
    .min(3, "The last name field must be at least 3 characters.")
    .matches(/^[A-Za-z]+$/, "The last name field format is invalid")
    .max(50, "The last name field must not greater than 50 characters.")
    .required("The last name field is required."),
  City: Yup.string()
    .min(3, "The city field must be at least 3 characters.")
    .max(30, "The city field must not greater than 30 characters.")
    .required("The city field is required."),
  Zip: Yup.string()
    .min(5, "The zip must be at least 5 characters.")
    .max(9, "The zip must not greater than 9 characters.")
    .required("The zip field is required."),
  product: Yup.string().required("The gift field is required."),

  product: Yup.string().when("CertiStatus", {
    is: "used",
    then: Yup.string().required(
      'The gift field is required when status is set to "used"'
    ),
    otherwise: Yup.string(),
  }),
  redemDate: Yup.string().when("CertiStatus", {
    is: "used",
    then: Yup.string().required(
      'The redemption date field is required when status is set to "used"'
    ),
    otherwise: Yup.string(),
  }),

  CertiStatus: Yup.string().required(
    "The Certificate Status field is required."
  ),
});
export const pavlinaUpdateVoucher = Yup.object({
  Company: Yup.string()
    .min(3, "The company must be at least 3 characters.")
    .max(50, "The company must not greater than 50 characters.")
    .required("The company field is required"),
  Certificate: Yup.string()
    .min(11, "The certificate must be at least 11 characters.")
    .required("The certificate field is required.	"),

  Job: Yup.number()
    .min(0, "The job field is required.")
    .required("The job field is required."),

  Denomination: Yup.number().notRequired(),

  CertiStatus: Yup.string().required(
    "The Certificate Status field is required."
  ),
});

export const validateVoucher = Yup.object({
  voucher: Yup.string()
    .max(11, "The Certificate Number should not exceed 11 characters.")
    .required("Please Enter Certificate Number."),

  check: Yup.boolean().oneOf([true], "Please Check the information Checkbox."),
  captchaValidate: Yup.boolean().oneOf(
    [true],
    "You can't leave reCaptcha Empty"
  ),
});

export const orderForVoucher = Yup.object({
  First: Yup.string().required("The first name field is required.	"),

  State: Yup.string().required("The state field is required."),
  res_address: Yup.string()
    .required("The residential address field is required.")
    .min(3, "The Address must be at least 3 characters."),

  Last: Yup.string().required("The last name field is required."),
  City: Yup.string().required("The city field is required."),
  Zip: Yup.string()
    .min(5, "The Zip must be at least 5 characters.")
    .max(9, "The Zip may not be greater than 9 characters.")
    .required("The zip field is required."),
  email: Yup.string()
    .email("Please enter a valid email")
    .required("The email field is required."),
  phone: Yup.string()
    .min(10, "The Phone must be at least 10 characters.")
    .max(12, "The Phone may not be greater than 12 characters.")
    .matches(/^[0-9- ]+$/, "Invalid phone number")
    .required("The phone field is required."),
});

export const contactUser = Yup.object({
  name: Yup.string().required("Name is required."),
  description: Yup.string().required("Description is required."),
  comments: Yup.string().required("Comment is required."),
  subject: Yup.string().required("Subject is required."),

  email: Yup.string()
    .email("Please enter a valid Contact Email")
    .required("Email is required."),
  phone: Yup.string()
    .min(10, "The phone must be at least 10 characters.")
    .max(12, "The phone may not be greater than 12 characters.")
    .matches(/^[0-9- ]+$/, "Invalid phone ")
    .required("Phone is required."),
});
